<template>
  <van-field v-model="code" :label="fieldLabel" :required="required" type="number" :name="'code_'+mobile" placeholder="请输入验证码" class="filed" @blur="changeCode(code)">
    <template v-if="formType==='login'" #left-icon>
      <img src="/static/code.png" class="icon" />
    </template>
    <template #extra>
      <span class="fieldRemark" @click="getCode()">{{btnText}}</span>
    </template>
  </van-field>
</template>

<script>
import {
    mapGetters,mapState
} from 'vuex';
import {
  Toast
} from "vant";
import {
  checkCellphone
} from '@/utils/utils';
import {
  sendVerifyCode
} from '@/api/api'
export default {
  name: "mobileCode",
  components: {
    sendVerifyCode,
  },
  props: {
    required: false,
    mobile: '',
    fieldLabel: '',
    field: '',
    formType: '',
    projectId: null,
  },
  data() {
    return {
      btnText: '获取验证码',
      btnDisabled: false,
      seconds: 60,
      code: '',
    };
  },
  computed: {
    ...mapGetters(['project_id']),
    ...mapState(['doctorInfo','basicInformation'])
  },
  methods: {
    startTimer() {
      this.btnDisabled = true;
      this.btnText = this.seconds + 's';
      this.timer = setTimeout(() => {
        this.seconds = this.seconds - 1;
        if (this.seconds <= 0) {
          clearTimeout(this.timer);
          this.seconds = 60;
          this.btnText = '获取验证码';
          this.btnDisabled = false;
        } else {
          this.startTimer();
        }
      }, 1000);
    },
    async getCode() {
      console.log("this.info",this.doctorInfo)
      console.log("项目id:",this.projectId ? this.projectId :this.basicInformation.projects[0].id)
      //return
      if (this.btnDisabled) return
      if (!this.mobile) {
        Toast('请输入手机号')
        return false
      } else if (!checkCellphone(this.mobile)) {
        Toast('手机号格式有误')
        return false;
      }
      try {
        let res = await sendVerifyCode({
          mobile: this.mobile,
          // projectId: this.project_id?this.project_id:this.projectId,
          projectId: this.projectId ? this.projectId :this.basicInformation.projects[0].id
        })
        Toast(res.message)
        if (res.status) {
          this.startTimer()
        }
      } catch (err) {
        Toast(err.response.data.message)
      }
    },
    changeCode(value) {
      this.$emit('changeFormCode', value, this.field);
    },
  },
}
</script>

<style >
.fieldRemark{
  white-space: nowrap;
  font-size: 14px;
  color: #4B99FF;
}
</style>