<template>
  <div class="set">
    <van-cell-group>
      <!-- <template #title>
                <div style="text-align:left;color:#232323;font-size:15px;margin-bottom:10px;">选择个人设置</div>
            </template> -->
      <template v-if="userInfo.doctor && this.userInfo.doctor.login_type !== 'loginNoPassword'">
        <!-- 对接登录的用户不显示此两项 -->
        <van-cell class="set_item" v-if="canChangeMobile" :value="is_mobile_verify == 0 ? '修改手机号' : '修改实名制认证信息'" is-link @click="handleChangeMobile" />
        <van-cell v-if="canChangePassword" value="设置密码" is-link @click="passWord" />
      </template>
      <van-cell value="退出登录" is-link @click="logout" />
    </van-cell-group>

    <van-dialog v-model="showChangePassword" title="设置密码" :show-confirm-button="false">
      <van-form style="margin: 16px 10px" input-align="left">
        <van-field v-model="newPassword" required name="newPassword" label="新密码" placeholder="请输入8-18位新密码" type="password" />
        <van-field v-model="rePassword" required name="rePassword" label="再次输入" placeholder="请输入8-18位新密码" type="password" />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="confirm">确认</van-button>
        </div>
        <div style="margin: 16px">
          <van-button round block type="default" native-type="submit" class="up" @click="showChangePassword = false">取消</van-button>
        </div>
      </van-form>
    </van-dialog>

    <van-dialog v-model="showChangeMobile" title="修改手机号" :show-confirm-button="false">
      <van-form class="form" input-align="left">
        <van-field readonly v-model="oldMobile" label="原手机号" />
        <!-- <mobileCode :mobile="oldMobile" :fieldLabel="'输入验证码'" :field="'oldMobileCode'" :formType="'set'" :required="true" @changeFormCode="changeFormCode"></mobileCode> -->
        <van-field v-model="newMobile" label="新手机号" required placeholder="请输入新手机号" type="digit" />
        <mobileCode ref="mobileCodeRef" :mobile="newMobile" :projectId="basicInformation.projects[0].id" :fieldLabel="'输入验证码'" :field="'newMobileCode'" :formType="'set'" :required="true" @changeFormCode="changeFormCode"></mobileCode>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="doChangeMobile">确认</van-button>
        </div>
        <div style="margin: 16px">
          <van-button round block type="default" native-type="submit" class="up" @click="showChangeMobile = false">取消</van-button>
        </div>
      </van-form>
    </van-dialog>

    <!-- 实名制认证弹窗 -->
    <mobileVerify v-if="showVerify && !doctorInfo.has_empower" :ifShowBank="ifShowBank" :showBankcardPhoto="ifShowBankcardPhoto" :projectId="loginProjectId" :afterModifyMobile="afterModifyMobile" :doctorId="userId" :bankcard="doctorInfo.bankcard" :resetDoctorInfo="true" :title="'实名制验证'" :tips="'实名制手机号需和银行卡预留手机号一致'" :tips_remain="'认证次数剩余' + valid_times + '次'" @hideVerify="hideVerify"></mobileVerify>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { checkCellphone } from '@/utils/utils'
import { setPassword, loginOut, changeMobile, projectDoctor } from '@/api/api'
import mobileCode from '@/components/mobileCode.vue'
import mobileVerify from '@/components/mobileVerify.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  name: '',
  components: {
    mobileCode,
    changeMobile,
    mobileVerify
  },
  data() {
    return {
      showChangePassword: false,
      newPassword: '',
      rePassword: '',
      canChangeMobile: false,
      canChangePassword: true,
      showChangeMobile: false,
      oldMobile: '',
      oldMobileCode: '',
      newMobile: '',
      newMobileCode: '',
      showVerify: false, //是否显示实名制认证弹窗
      is_mobile_verify: 0, //是否已经实名
      has_record: false //是否有病例在支付中
    }
  },
  computed: {
    ...mapGetters(['mobile']),
    ...mapState(['userId', 'doctorInfo', 'basicInformation', 'valid_times', 'userInfo']),
    ifShowBank() {
      return this.basicInformation.subprojects[0].is_bankname_verify == 1
    },
    ifShowBankcardPhoto() {
      return this.basicInformation.subprojects[0].is_collection_bankcard_photo == 1
    },
    loginProjectId() {
      return this.doctorInfo.projects ? this.doctorInfo.projects[0].id : this.doctorInfo.login_project_id
    }
  },
  mounted() {
    projectDoctor()
      .then(res => {
        this.is_mobile_verify = res.is_mobile_verify
        this.has_record = res.has_record
      })
      .catch(err => {
        Toast(err.response.data.message)
      })
    if (this.basicInformation.login_type == 'loginCode') {
      //验证码登陆,可以修改手机号
      this.canChangeMobile = true
    }
    if (this.doctorInfo.has_empower) {
      //是志愿者,隐藏修改手机号
      this.canChangeMobile = false
    }
    //旧电话号码回显
    console.log('this.doctorInfo', this.doctorInfo)
    console.log('basicInformation@set', this.basicInformation)
    console.log('项目id@set', this.basicInformation.projects[0].id)
    this.oldMobile = this.doctorInfo.mobile.substr(0, 3) + '****' + this.doctorInfo.mobile.substr(7)
  },
  methods: {
    ...mapActions(['SET_DOCTOR_INFO']),
    logout() {
      loginOut()
        .then(res => {
          localStorage.removeItem('outDoctor')
          Toast('退出成功')
          this.$router.replace({
            path: '/login'
          })
        })
        .catch(err => {
          Toast(err.response.data.message)
        })
    },
    passWord() {
      this.showChangePassword = true
      this.newPassword = ''
      this.rePassword = ''
    },
    async confirm() {
      if (!this.newPassword || !this.rePassword) {
        Toast('更换失败，请完善信息')
        return false
      } else if (this.newPassword.length > 18 || this.newPassword.length < 8) {
        Toast('更换失败，请输入8-18位数的密码')
        return false
      } else if (this.newPassword != this.rePassword) {
        Toast('更换失败，请输入相同的密码')
        return false
      } else if (0) {
        //新密码和其他项目密码相同
        Toast('密码安全性过低,请更换其他密码')
        return false
      }
      try {
        await setPassword({
          mobile: this.mobile,
          password: this.newPassword,
          rePassword: this.rePassword,
          projectId: localStorage.getItem('project_id')
        }).then(res => {
          Toast(res.success)
          this.showChangePassword = false
        })
      } catch (err) {
        Toast(err.response.data.message)
      }
    },
    changeFormCode(value, field) {
      if (field === 'oldMobileCode') {
        this.oldMobileCode = value
      } else if (field === 'newMobileCode') {
        this.newMobileCode = value
      }
    },
    async doChangeMobile() {
      // if (!this.oldMobile) {
      //   Toast('请输入原手机号')
      //   return false
      // } else if (!checkCellphone(this.oldMobile)) {
      //   Toast('原手机号格式有误')
      //   return false;
      // }
      // if (!this.oldMobileCode) {
      //   Toast('请输入原手机号验证码')
      //   return false
      // }
      if (!this.newMobile) {
        Toast('请输入新手机号')
        return false
      } else if (!checkCellphone(this.newMobile)) {
        Toast('新手机号格式有误')
        return false
      }
      if (!this.newMobileCode) {
        Toast('请输入新手机号验证码')
        return false
      }
      if (this.oldMobile === this.newMobile) {
        Toast('新手机号不能与原手机号相同')
        return false
      }
      let loading = Toast.loading({ duration: 0, forbidClick: true, message: '提交中...' })
      try {
        let res = await changeMobile({
          doctor_id: this.userId,
          oldMobile: this.doctorInfo.mobile,
          // oldMobileCode: this.oldMobileCode,
          newMobile: this.newMobile,
          newMobileCode: this.newMobileCode
        })
        this.SET_DOCTOR_INFO(res)
        this.showChangeMobile = false
        this.oldMobile = this.doctorInfo.mobile.substr(0, 3) + '****' + this.doctorInfo.mobile.substr(7)
        this.newMobile = ''
        this.newMobileCode = ''
        this.$refs.mobileCodeRef.code = ''
        loading.clear()
        Toast({
          duration: 10000,
          message: '修改成功'
        })
      } catch (err) {
        loading.clear()
        Toast(err.response.data.message)
      }
    },
    // 关闭验证弹窗
    hideVerify(is_hide) {
      this.showVerify = is_hide
      console.log('关闭验证弹窗')
    },

    afterModifyMobile() {
      this.showVerify = false
    },

    handleChangeMobile() {
      if (this.is_mobile_verify == 0) {
        this.showChangeMobile = true
      } else {
        if (this.has_record) {
          Toast({
            message: '有项目病例正在支付中，请稍后修改'
          })
        } else {
          if (this.valid_times === 0) {
            Dialog.alert({
              message: '您的实名认证次数已经用完，请联系相关负责人解锁次数。',
              confirmButtonColor: '#1989fa'
            }).then(() => {
              // on close
            })
          } else {
            this.showVerify = true
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.set {
  .form {
    // background: peachpuff;
    margin: 16px 3px;
  }
  ::v-deep .van-field__label {
    margin-right: 0 !important;
  }
  .up {
    font-size: 15px;
    font-family: PingFangSC-Medium;
  }
  .van-cell {
    padding: 20px 16px;
    font-size: 16px;
  }
}
</style>
