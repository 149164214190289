<template>
  <div class="mobile_verify" :class="showBankcardPhoto ? 'max-height-style' : ''">
    <van-dialog v-model="showDialog" @close="closeDialog" close-on-click-overlay :title="title" :show-confirm-button="false">
      <!-- 弹窗右上角显示关闭按钮 -->
      <van-icon @click="closeDialog" class="close_btn" size="20" name="cross" />
      <van-form class="from" style="margin: 16px 10px" input-align="left">
        <div v-if="tips" class="tips">{{ tips }}</div>
        <div v-if="tips_remain" class="tips">{{ tips_remain }}</div>
        <van-field v-model="mobileNumber" label="手机号" required placeholder="手机号" type="digit" />
        <mobileCode :fieldLabel="'验证码'" :mobile="mobileNumber" :projectId="projectId" :formType="'set'" required @changeFormCode="changeFormCode"></mobileCode>
        <div v-if="showBankcardPhoto">
          <div class="uploadTitle">
            <span>上传银行卡照片</span>
          </div>
          <div class="ocrBox">
            <template v-if="isWeixin">
              <div class="upload-img" @click="chooseImage()">
                <div class="upload-img-a" :style="{ backgroundImage: `url(${bankcard_front ? bankcard_front : '/static/bankcard_front.png'})` }"></div>
              </div>
            </template>
            <ImageUploader v-else class="upload-img" v-model="images" @change="changeImg">
              <template slot="content">
                <div class="upload-img-a" :style="{ backgroundImage: `url(${bankcard_front ? bankcard_front : '/static/bankcard_front.png'})` }"></div>
              </template>
            </ImageUploader>
          </div>
          <p class="bankcard-photo-tips">如果提取银行卡信息有误，请重新上传银行卡图片，或换银行卡</p>
        </div>

        <van-field v-model="verifyBankcard" label="银行卡号" required placeholder="银行卡号" type="digit" :readonly="showBankcardPhoto" />
        <!-- @目前安心为尼项目（安心守护）、安欣保（安心首选）项目、靶向治疗二期、例例生辉二期、鳞癌长疗程项目显示开户行和开户支行(ifShowBank)-->
        <van-field label="开户行" required readonly name="picker" placeholder="请选择xx银行" :value="bankForm.bank_name" rows="1" autosize maxlength="500" type="textarea" right-icon="arrow-down" @click="showBankList = !showBankList" v-if="ifShowBank" />
        <van-field v-model="bankForm.bank_branch" label="开户支行" required placeholder="开户支行必须填写XX省XX市XX支行" rows="1" autosize maxlength="500" type="textarea" v-if="ifShowBank" />

        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit" color="#4B99FF" class="up" @click="verifySubmit">提交</van-button>
          <van-button v-if="get_verify_apply == 1 ? true : false" round block color="#64C5C5" class="apply_btn" @click="toApplyPage()">人工验证申请通道</van-button>
          <div class="status_text" v-if="get_verify_apply == 1 && (audit_status == '审核驳回' || audit_status == '等待审核') ? true : false">审核状态:{{ audit_status }}</div>
          <div class="status_text" v-if="get_verify_apply == 1 && audit_status == '审核驳回' ? true : false">可点击人工验证申请通道查看具体原因</div>
        </div>
      </van-form>
    </van-dialog>
    <van-popup v-model="showBankList" round position="bottom" :style="{ height: '400px' }" @click-overlay="showBankList = false">
      <!-- <form action="/">
        <van-search v-model="bankKeyword" placeholder="请输入搜索关键词" @clear="bankKeyword = ''" />
        <van-picker :default-index="findIndex(bankList, bankForm.bank_name)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" :style="{height:'246px'}" @cancel="showBankList = false" @confirm="confirmBank" />
        </form> -->
      <van-picker :default-index="findIndex(bankList, bankForm.bank_name)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" @cancel="showBankList = false" @confirm="confirmBank">
        <template #title>
          <van-search class="bank_search" v-model="bankKeyword" input-align="left" shape="round" placeholder="搜索开户行名称" @clear="bankKeyword = ''" />
        </template>
      </van-picker>
    </van-popup>
  </div>
</template>

<script>
import { appHost } from '@/utils/host'
import { verifyMobile, loginOut, projectDoctor, addBankList, queryBankcard, DownloadImage } from '@/api/api'
import mobileCode from '@/components/mobileCode.vue'
import { Toast, Dialog } from 'vant'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { parseUrl } from '@/utils/utils'
export default {
  name: 'mobileVerify',
  components: {
    mobileCode
  },
  props: {
    resetDoctorInfo: true,
    afterModifyMobile: null,
    showBankcardPhoto: false,
    title: '',
    tips: '',
    doctorId: '',
    mobile: '',
    bankcard: '',
    projectId: null,
    tips_remain: '', //实名制验证次数提示
    ifShowBank: false //是否显示开户行及开户支行
  },
  data() {
    return {
      baseFileUrl: appHost,
      showDialog: true,
      // verifyMobile: '',
      bankcard_front: '',
      verifyBankcard: '',
      images: [],
      code: '', //从mobileCode中获取的验证码值
      mobileNumber: '', //传递给mobileCode的电话号码
      // isDisabled: true,
      // 银行卡信息
      showBank: false,
      bankList: [],
      showBankList: false,
      bankKeyword: '', // 开户银行关键字
      bankForm: {
        bank_name: '', // 开户行
        bank_branch: '' // 开户支行
      },
      bankDisabled: false,
      ifShowApplyBtn: false,
      audit_status: '' //审核状态
    }
  },
  mounted() {
    this.mobileNumber = this.mobile
    this.verifyBankcard = this.bankcard
    this.audit_status = this.verify.apply.status
    console.log('mounted', this.verifyBankcard, this.code)
    console.log('是否显示：', this.ifShowBank)
    this.init()
  },
  computed: {
    ...mapState(['valid_times', 'doctor_id', 'verify', 'basicInformation']),
    ...mapGetters(['sub_id', 'get_verify_apply'])
  },
  methods: {
    ...mapMutations(['setValidTimes']),
    ...mapActions(['SET_DOCTOR_INFO']),
    async init() {
      console.log('银行卡doctor_id@', this.doctor_id)

      let data = await addBankList({ project_doctor_id: this.doctor_id }) //获取银行卡列表
      this.bankList = data.options
    },
    findIndex(arr, val) {
      for (let i in arr) {
        let ele = arr[i]
        if (ele.name == ele.val) {
          // console.log('findIndex', i)
          this.$forceUpdate
          return i
        }
      }
    },
    getOption(list, val) {
      let arr = list.filter(item => {
        if (item.value) {
          return item.value.indexOf(val) != -1
        } else if (item.name) {
          return item.name.indexOf(val) != -1
        }
      })
      // return val?arr:[]
      return val ? arr : list //输入框为空时显示银行卡列表
    },
    confirmBank(val) {
      if (val) {
        this.bankForm.bank_name = val.value
        this.showBankList = false
      } else {
        Toast('请先输入关键字查找开户行')
      }
    },
    verifySubmit() {
      console.log('sub_id', this.sub_id)
      console.log('实名制验证剩余次数：', this.valid_times)
      if (!this.mobileNumber) {
        Toast('手机号不能为空')
        return false
      }
      if (!this.code) {
        Toast('验证码不能为空')
        return false
      }
      if (this.showBankcardPhoto && !this.bankcard_front) {
        Toast('请上传银行卡照片')
        return false
      }
      if (!this.verifyBankcard) {
        Toast('银行卡号不能为空')
        return false
      }
      if (this.ifShowBank == true && !this.bankForm.bank_name) {
        Toast('开户行不能为空')
        return false
      }
      if (this.ifShowBank == true && !this.bankForm.bank_branch) {
        Toast('开户支行不能为空')
        return false
      }
      if (this.bankForm.bank_branch && this.basicInformation.projects[0].short_name === 'sshy') {
        const tempStr = this.bankForm.bank_branch
        if (!tempStr.includes('市')) {
          Toast('支行信息填写有误')
          return false
        }
      }
      console.log('开户行:', this.bankForm.bank_name)
      console.log('开户支行', this.bankForm.bank_branch)
      let loading = Toast.loading({ duration: 0, forbidClick: true, message: '提交中...' })
      if (this.valid_times == 0) {
        loading.clear()
        Dialog.alert({
          title: '实名制验证次数不足',
          message: '请联系相关项目工作人员',
          confirmButtonColor: '#4B99FF'
        }).then(() => {
          // let is_hide = false
          // this.$emit('hideVerify',is_hide)
        })
      } else {
        verifyMobile({ doctor_id: this.doctorId, mobile: this.mobileNumber, bankcard: this.verifyBankcard, bankcard_front: this.bankcard_front, code: this.code, subproject_id: this.sub_id, projectId: this.projectId, bank_name: this.bankForm.bank_name, bank_branch: this.bankForm.bank_branch })
          .then(res => {
            if (this.resetDoctorInfo) this.SET_DOCTOR_INFO(res)
            console.log('res', res)
            loading.clear()
            Toast('手机号修改成功')
            if (this.afterModifyMobile) {
              this.afterModifyMobile({ doctor_id: this.doctorId, mobile: this.mobileNumber, bankcard: this.verifyBankcard })
            }
          })
          .catch(err => {
            loading.clear()
            console.log('err', err)
            Dialog.alert({
              message: err.response.data.message,
              title: '温馨提示',
              confirmButtonColor: '#4B99FF'
            })
          })
          .finally(() => {
            projectDoctor().then(res => {
              console.log('res', res)
              console.log('xxx', res.bankcard_api_call_count)
              this.setValidTimes(res.bankcard_api_call_count)
            })
          })
      }
    },
    changeFormCode(value, field) {
      this.code = value
    },
    closeDialog() {
      let is_hide = false
      this.$emit('hideVerify', is_hide) //关闭验证弹窗
    },
    toApplyPage() {
      console.log('点击人工验证申请通道')
      this.$router.push({
        path: '/apply'
      })
    },
    chooseImage() {
      this.$wechat.chooseImage({
        count: 1,
        // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: async res => {
          try {
            const serverId = res.localIds[0]
            this.$wechat.getLocalImgData({
              localId: res.localIds[0],
              success: async response => {
                let localData = response.localData
                let itemSize = await this.getImgByteSize(localData) //获取base64大小
                console.log(itemSize, 'itemSize')
                // alert(itemSize)
                if (itemSize > 20480000) {
                  Toast('图片过大，请重新选择图片')
                } else {
                  // alert("this.uploadImage(serverId)")
                  await this.uploadImage(serverId)
                }
              }
            })
          } catch (err) {
            let str = JSON.stringify(err.response)
            let json = JSON.parse(str)
            console.log('err1', json)
            Dialog.alert({
              title: '提示',
              message: json.data.data.message,
              confirmButtonColor: '#4B99FF'
            })
          }
        },
        fail: function () {
          Toast('选图失败')
        }
      })
    },
    uploadImage(serverId) {
      return new Promise((resolve, reject) => {
        this.$wechat.uploadImage({
          localId: serverId,
          isShowProgressTips: 1,
          success: async res => {
            var serverId = res.serverId // 返回图片的服务器端ID
            Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true
            })
            try {
              let res = await DownloadImage({
                params: {
                  serverId
                }
              })
              const bandcardImg = parseUrl(res, this.baseFileUrl)
              this.verifyBankcard = await queryBankcard({
                project_id: this.basicInformation.projects[0].id,
                bankcard_front: bandcardImg
              })
              this.bankcard_front = bandcardImg
              console.log('this.verifyBankcard', this.verifyBankcard)
              Toast.clear()
            } catch (err) {
              Toast.clear()
              Toast(err.response.data.message)
              reject(err)
            }
          },
          fail: function () {
            Toast('图片上传失败')
          }
        })
      })
    },
    async changeImg() {
      const len = this.images.length
      if (len > 0) {
        this.verifyBankcard = await queryBankcard({
          project_id: this.basicInformation.projects[0].id,
          bankcard_front: this.images[0]
        })
        this.bankcard_front = this.images[0]
      } else {
        this.bankcard_front = '/static/bankcard_front.png'
        this.verifyBankcard = ''
      }
    },
    getImgByteSize(data) {
      let size = 0
      if (data) {
        // 获取base64图片byte大小
        const equalIndex = data.indexOf('=') // 获取=号下标
        if (equalIndex > 0) {
          const str = data.substring(0, equalIndex) // 去除=号
          const strLength = str.length
          const fileLength = strLength - (strLength / 8) * 2 // 真实的图片byte大小
          size = Math.floor(fileLength) // 向下取整
        } else {
          const strLength = data.length
          const fileLength = strLength - (strLength / 8) * 2
          size = Math.floor(fileLength) // 向下取整
        }
      }
      return size * 1.657
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadTitle {
  text-align: left;
  font-size: 14px;
  color: #646566;
  margin-bottom: 13px;
  margin-left: 9px;
  margin-right: 16px;
  &::before {
    color: #ee0a24;
    content: '*';
  }
}
.ocrBox {
  height: 128px;
  width: 100%;
  display: flex;
  justify-content: center;

  .upload-img {
    // height: 94px;
    // width: 139px;
    height: 96px;
    width: 165px;
    background-position: left top, right top, right bottom, left bottom;
    background-repeat: no-repeat;
    background-size: 16px;
    // padding: 20px;
    padding: 15px;

    .upload-img-a,
    .upload-img-b {
      width: 100%;
      height: 0;
      padding-top: 63%;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
      border-radius: 5px;
    }
  }
}
.bankcard-photo-tips {
  margin: auto;
  padding: 0 40px;
  color: #bd3124;
  font-size: 14px;
  font-family: PingFangSC-regular;
}
.max-height-style {
  ::v-deep .van-dialog {
    max-height: 464px;
    overflow: auto;
  }
}
.mobile_verify {
  ::v-deep .van-picker__cancel,
  ::v-deep .van-picker__confirm {
    word-break: keep-all !important;
  }
  .bank_search {
    width: 100%;
    padding: 10px 0;
    .van-field {
      padding: 5px 0;
    }
  }
  .tips {
    // color: #B8B8B8;
    color: #565656;
    font-size: 13px;
  }
  .from {
    .up {
      font-size: 15px;
      font-family: PingFangSC-Medium;
    }
    .apply_btn {
      margin-top: 10px;
      font-size: 15px;
      font-family: PingFangSC-Medium;
    }
    .status_text {
      color: #ff0000;
      font-size: 14px;
      margin-top: 10px;
    }
  }
  .van-dialog {
    border-radius: 8px;
    position: fixed;
    .close_btn {
      position: absolute;
      right: 25px;
      top: 25px;
    }
  }
  .van-field {
    padding: 14px 16px;
  }
}
</style>
